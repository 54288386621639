import auctionSlugs from '@autobid/apps-nuxt-config/i18n/auctionSlugs'
import { unref } from 'vue'
import type { MaybeRef } from 'vue'
import { AGGREGATE_BID_ID } from '@autobid/ui/constants/AGGREGATE_BID_ID'
import { getAggregateBidSlug } from '@autobid/ui/utils/car/getAggregateBidSlug'
import { getIdFromSlug } from '@autobid/ui/utils/car/getIdFromSlug'
import { useSupportedLangCodes } from '@autobid/strapi-integration/composable/useSupportedLangCodes'
import { useApp } from './useApp'

export const PAGES_MAP = {
  'car-list': 'auction/[slug]',
  current: 'auction/current/[slug]',
  barometer: 'item/[slug]/index',
  details: 'item/[slug]/details'
}

type Page = keyof typeof PAGES_MAP

type Props = {
  page: Page
  slug: Ref<string> | string
  lang?: string
}

/**
 * Get auction link based on a type of the page (e.g. barometer, details)
 */
export const useAuctionPagesLink = (appId?: MaybeRef<number>) => {
  const app = useApp(appId)
  const { locale } = useI18n()
  const {
    APP_ID,
    autobidCMS: { language: defaultLanguage }
  } = useRuntimeConfig().public
  const push = usePush()
  const { t } = useI18n()
  const isAggregateBid = unref(appId) === AGGREGATE_BID_ID
  const { validate } = useSupportedLangCodes()

  const getLink = ({ page, slug, lang }: Props) => {
    const _lang = !lang ? locale.value : validate(lang) ? lang : defaultLanguage
    let baseUrl = '/'
    const fullPageSlug = PAGES_MAP[page]

    if (unref(appId) && unref(appId) !== Number(APP_ID)) {
      if (!app?.value?.url) return undefined

      baseUrl = app.value.url
    }

    if (isAggregateBid) {
      const carId = getIdFromSlug(unref(slug))
      const aggregateSlug = getAggregateBidSlug(carId, _lang, page)

      return `${baseUrl}${aggregateSlug}`
    }

    const i18nSlugFromConfig = auctionSlugs[fullPageSlug][_lang]

    if (!i18nSlugFromConfig) {
      const error = t('missing-lang-configuration', { lang: _lang })
      push.error(error)

      return `${baseUrl}${_lang}`
    }

    return `${baseUrl}${_lang}${auctionSlugs[fullPageSlug][_lang].replace(
      '[slug]',
      unref(slug)
    )}`
  }

  return { getLink }
}
